@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=IBM+Plex+Sans:400,700&display=swap&subset=cyrillic");
/*
	Atmosphere by Pixelarity
	pixelarity.com | hello@pixelarity.com
	License: pixelarity.com/license
*/
@font-face {
  font-family: "Sriracha";
  src: url("/assets/webfonts/Sriracha.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
html, body, div, span, applet, object,
iframe, h1, h2, h3, h4, h5, h6, p, blockquote,
pre, a, abbr, acronym, address, big, cite,
code, del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var, b,
u, i, center, dl, dt, dd, ol, ul, li, fieldset,
form, label, legend, table, caption, tbody,
tfoot, thead, tr, th, td, article, aside,
canvas, details, embed, figure, figcaption,
footer, header, hgroup, menu, nav, output, ruby,
section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

body {
  -webkit-text-size-adjust: none;
}

mark {
  background-color: transparent;
  color: inherit;
}

input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input, select, textarea {
  -moz-appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
}

/* Basic */
@-ms-viewport {
  width: device-width;
}
body {
  -ms-overflow-style: scrollbar;
}

@media screen and (max-width: 480px) {
  html, body {
    min-width: 320px;
  }
}
html {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

*, *:before, *:after {
  -webkit-box-sizing: inherit;
          box-sizing: inherit;
}

body {
  background: #fff;
}
body.is-preload *, body.is-preload *:before, body.is-preload *:after {
  -webkit-animation: none !important;
  animation: none !important;
  -webkit-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}

/* Type */
body {
  background-color: #fff;
  color: #637485;
}

body, input, select, textarea {
  font-family: "IBM Plex Sans", Helvetica, sans-serif;
  font-size: 14pt;
  font-weight: 400;
  line-height: 1.625;
}
@media screen and (max-width: 1680px) {
  body, input, select, textarea {
    font-size: 12pt;
  }
}
@media screen and (max-width: 1280px) {
  body, input, select, textarea {
    font-size: 12pt;
  }
}
@media screen and (max-width: 980px) {
  body, input, select, textarea {
    font-size: 12pt;
  }
}
@media screen and (max-width: 736px) {
  body, input, select, textarea {
    font-size: 12pt;
  }
}
@media screen and (max-width: 480px) {
  body, input, select, textarea {
    font-size: 12pt;
  }
}
@media screen and (max-width: 360px) {
  body, input, select, textarea {
    font-size: 11pt;
  }
}

a {
  text-decoration: underline;
}
a:hover {
  text-decoration: none;
}

a[href^="tel:"] {
  text-decoration: none;
}

a[href^="mailto:"] {
  text-decoration: none;
}

strong, b {
  font-weight: 700;
}

em, i {
  font-style: italic;
}

p {
  margin: 0 0 2em 0;
}

h1, h2, h3, h4, h5, h6 {
  line-height: 1.3;
  margin: 0 0 1em 0;
}
h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
  color: inherit;
  text-decoration: none;
}

h1 {
  font-size: 2em;
}

h2 {
  font-size: 1.75em;
}

h3 {
  font-size: 1.35em;
}

h4 {
  font-size: 1em;
}

h5 {
  font-size: 0.9em;
}

h6 {
  font-size: 0.7em;
}

sub {
  font-size: 0.8em;
  position: relative;
  top: 0.5em;
}

sup {
  font-size: 0.8em;
  position: relative;
  top: -0.5em;
}

blockquote {
  border-left: solid 4px;
  font-style: italic;
  margin: 0 0 2em 0;
  padding: 0.5em 0 0.5em 2em;
}

code {
  -webkit-border-radius: 4px;
          border-radius: 4px;
  border: solid 1px;
  font-family: "Courier New", monospace;
  font-size: 0.9em;
  margin: 0 0.25em;
  padding: 0.25em 0.65em;
}

pre {
  -webkit-overflow-scrolling: touch;
  font-family: "Courier New", monospace;
  font-size: 0.9em;
  margin: 0 0 2em 0;
}
pre code {
  display: block;
  line-height: 1.75;
  padding: 1em 1.5em;
  overflow-x: auto;
}

hr {
  border: 0;
  border-bottom: solid 1px;
  margin: 2em 0;
}
hr.major {
  margin: 3em 0;
}

.align-left {
  text-align: left;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

@media screen and (max-width: 736px) {
  h1 {
    font-size: 1.75em;
  }

  h2 {
    font-size: 1.5em;
  }

  h3 {
    font-size: 1.25em;
  }
}
input, select, textarea {
  color: #637485;
}

a {
  color: #39a288;
}

strong, b {
  color: #637485;
}

h1, h2, h3, h4, h5, h6 {
  color: #637485;
}

blockquote {
  border-left-color: rgba(99, 116, 133, 0.25);
}

code {
  background: rgba(99, 116, 133, 0.075);
  border-color: rgba(99, 116, 133, 0.25);
}

hr {
  border-bottom-color: rgba(99, 116, 133, 0.25);
}

/* Row */
.row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
}
.row > * {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.row.gtr-uniform > * > :last-child {
  margin-bottom: 0;
}
.row.aln-left {
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
.row.aln-center {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.row.aln-right {
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
.row.aln-top {
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
      -ms-flex-align: start;
          align-items: flex-start;
}
.row.aln-middle {
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.row.aln-bottom {
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
      -ms-flex-align: end;
          align-items: flex-end;
}
.row > .imp {
  -webkit-box-ordinal-group: 0;
  -webkit-order: -1;
      -ms-flex-order: -1;
          order: -1;
}
.row > .col-1 {
  width: 8.3333333333%;
}
.row > .off-1 {
  margin-left: 8.3333333333%;
}
.row > .col-2 {
  width: 16.6666666667%;
}
.row > .off-2 {
  margin-left: 16.6666666667%;
}
.row > .col-3 {
  width: 25%;
}
.row > .off-3 {
  margin-left: 25%;
}
.row > .col-4 {
  width: 33.3333333333%;
}
.row > .off-4 {
  margin-left: 33.3333333333%;
}
.row > .col-5 {
  width: 41.6666666667%;
}
.row > .off-5 {
  margin-left: 41.6666666667%;
}
.row > .col-6 {
  width: 50%;
}
.row > .off-6 {
  margin-left: 50%;
}
.row > .col-7 {
  width: 58.3333333333%;
}
.row > .off-7 {
  margin-left: 58.3333333333%;
}
.row > .col-8 {
  width: 66.6666666667%;
}
.row > .off-8 {
  margin-left: 66.6666666667%;
}
.row > .col-9 {
  width: 75%;
}
.row > .off-9 {
  margin-left: 75%;
}
.row > .col-10 {
  width: 83.3333333333%;
}
.row > .off-10 {
  margin-left: 83.3333333333%;
}
.row > .col-11 {
  width: 91.6666666667%;
}
.row > .off-11 {
  margin-left: 91.6666666667%;
}
.row > .col-12 {
  width: 100%;
}
.row > .off-12 {
  margin-left: 100%;
}
.row.gtr-0 {
  margin-top: 0;
  margin-left: 0em;
}
.row.gtr-0 > * {
  padding: 0 0 0 0em;
}
.row.gtr-0.gtr-uniform {
  margin-top: 0em;
}
.row.gtr-0.gtr-uniform > * {
  padding-top: 0em;
}
.row.gtr-25 {
  margin-top: 0;
  margin-left: -0.5em;
}
.row.gtr-25 > * {
  padding: 0 0 0 0.5em;
}
.row.gtr-25.gtr-uniform {
  margin-top: -0.5em;
}
.row.gtr-25.gtr-uniform > * {
  padding-top: 0.5em;
}
.row.gtr-50 {
  margin-top: 0;
  margin-left: -1em;
}
.row.gtr-50 > * {
  padding: 0 0 0 1em;
}
.row.gtr-50.gtr-uniform {
  margin-top: -1em;
}
.row.gtr-50.gtr-uniform > * {
  padding-top: 1em;
}
.row {
  margin-top: 0;
  margin-left: -2em;
}
.row > * {
  padding: 0 0 0 2em;
}
.row.gtr-uniform {
  margin-top: -2em;
}
.row.gtr-uniform > * {
  padding-top: 2em;
}
.row.gtr-150 {
  margin-top: 0;
  margin-left: -3em;
}
.row.gtr-150 > * {
  padding: 0 0 0 3em;
}
.row.gtr-150.gtr-uniform {
  margin-top: -3em;
}
.row.gtr-150.gtr-uniform > * {
  padding-top: 3em;
}
.row.gtr-200 {
  margin-top: 0;
  margin-left: -4em;
}
.row.gtr-200 > * {
  padding: 0 0 0 4em;
}
.row.gtr-200.gtr-uniform {
  margin-top: -4em;
}
.row.gtr-200.gtr-uniform > * {
  padding-top: 4em;
}
@media screen and (max-width: 1680px) {
  .row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
        -ms-flex-align: stretch;
            align-items: stretch;
  }
  .row > * {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
  }
  .row.gtr-uniform > * > :last-child {
    margin-bottom: 0;
  }
  .row.aln-left {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
  .row.aln-center {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .row.aln-right {
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
        -ms-flex-pack: end;
            justify-content: flex-end;
  }
  .row.aln-top {
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
  .row.aln-middle {
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .row.aln-bottom {
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
        -ms-flex-align: end;
            align-items: flex-end;
  }
  .row > .imp-xlarge {
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
        -ms-flex-order: -1;
            order: -1;
  }
  .row > .col-1-xlarge {
    width: 8.3333333333%;
  }
  .row > .off-1-xlarge {
    margin-left: 8.3333333333%;
  }
  .row > .col-2-xlarge {
    width: 16.6666666667%;
  }
  .row > .off-2-xlarge {
    margin-left: 16.6666666667%;
  }
  .row > .col-3-xlarge {
    width: 25%;
  }
  .row > .off-3-xlarge {
    margin-left: 25%;
  }
  .row > .col-4-xlarge {
    width: 33.3333333333%;
  }
  .row > .off-4-xlarge {
    margin-left: 33.3333333333%;
  }
  .row > .col-5-xlarge {
    width: 41.6666666667%;
  }
  .row > .off-5-xlarge {
    margin-left: 41.6666666667%;
  }
  .row > .col-6-xlarge {
    width: 50%;
  }
  .row > .off-6-xlarge {
    margin-left: 50%;
  }
  .row > .col-7-xlarge {
    width: 58.3333333333%;
  }
  .row > .off-7-xlarge {
    margin-left: 58.3333333333%;
  }
  .row > .col-8-xlarge {
    width: 66.6666666667%;
  }
  .row > .off-8-xlarge {
    margin-left: 66.6666666667%;
  }
  .row > .col-9-xlarge {
    width: 75%;
  }
  .row > .off-9-xlarge {
    margin-left: 75%;
  }
  .row > .col-10-xlarge {
    width: 83.3333333333%;
  }
  .row > .off-10-xlarge {
    margin-left: 83.3333333333%;
  }
  .row > .col-11-xlarge {
    width: 91.6666666667%;
  }
  .row > .off-11-xlarge {
    margin-left: 91.6666666667%;
  }
  .row > .col-12-xlarge {
    width: 100%;
  }
  .row > .off-12-xlarge {
    margin-left: 100%;
  }
  .row.gtr-0 {
    margin-top: 0;
    margin-left: 0em;
  }
  .row.gtr-0 > * {
    padding: 0 0 0 0em;
  }
  .row.gtr-0.gtr-uniform {
    margin-top: 0em;
  }
  .row.gtr-0.gtr-uniform > * {
    padding-top: 0em;
  }
  .row.gtr-25 {
    margin-top: 0;
    margin-left: -0.5em;
  }
  .row.gtr-25 > * {
    padding: 0 0 0 0.5em;
  }
  .row.gtr-25.gtr-uniform {
    margin-top: -0.5em;
  }
  .row.gtr-25.gtr-uniform > * {
    padding-top: 0.5em;
  }
  .row.gtr-50 {
    margin-top: 0;
    margin-left: -1em;
  }
  .row.gtr-50 > * {
    padding: 0 0 0 1em;
  }
  .row.gtr-50.gtr-uniform {
    margin-top: -1em;
  }
  .row.gtr-50.gtr-uniform > * {
    padding-top: 1em;
  }
  .row {
    margin-top: 0;
    margin-left: -2em;
  }
  .row > * {
    padding: 0 0 0 2em;
  }
  .row.gtr-uniform {
    margin-top: -2em;
  }
  .row.gtr-uniform > * {
    padding-top: 2em;
  }
  .row.gtr-150 {
    margin-top: 0;
    margin-left: -3em;
  }
  .row.gtr-150 > * {
    padding: 0 0 0 3em;
  }
  .row.gtr-150.gtr-uniform {
    margin-top: -3em;
  }
  .row.gtr-150.gtr-uniform > * {
    padding-top: 3em;
  }
  .row.gtr-200 {
    margin-top: 0;
    margin-left: -4em;
  }
  .row.gtr-200 > * {
    padding: 0 0 0 4em;
  }
  .row.gtr-200.gtr-uniform {
    margin-top: -4em;
  }
  .row.gtr-200.gtr-uniform > * {
    padding-top: 4em;
  }
}
@media screen and (max-width: 1280px) {
  .row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
        -ms-flex-align: stretch;
            align-items: stretch;
  }
  .row > * {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
  }
  .row.gtr-uniform > * > :last-child {
    margin-bottom: 0;
  }
  .row.aln-left {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
  .row.aln-center {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .row.aln-right {
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
        -ms-flex-pack: end;
            justify-content: flex-end;
  }
  .row.aln-top {
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
  .row.aln-middle {
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .row.aln-bottom {
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
        -ms-flex-align: end;
            align-items: flex-end;
  }
  .row > .imp-large {
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
        -ms-flex-order: -1;
            order: -1;
  }
  .row > .col-1-large {
    width: 8.3333333333%;
  }
  .row > .off-1-large {
    margin-left: 8.3333333333%;
  }
  .row > .col-2-large {
    width: 16.6666666667%;
  }
  .row > .off-2-large {
    margin-left: 16.6666666667%;
  }
  .row > .col-3-large {
    width: 25%;
  }
  .row > .off-3-large {
    margin-left: 25%;
  }
  .row > .col-4-large {
    width: 33.3333333333%;
  }
  .row > .off-4-large {
    margin-left: 33.3333333333%;
  }
  .row > .col-5-large {
    width: 41.6666666667%;
  }
  .row > .off-5-large {
    margin-left: 41.6666666667%;
  }
  .row > .col-6-large {
    width: 50%;
  }
  .row > .off-6-large {
    margin-left: 50%;
  }
  .row > .col-7-large {
    width: 58.3333333333%;
  }
  .row > .off-7-large {
    margin-left: 58.3333333333%;
  }
  .row > .col-8-large {
    width: 66.6666666667%;
  }
  .row > .off-8-large {
    margin-left: 66.6666666667%;
  }
  .row > .col-9-large {
    width: 75%;
  }
  .row > .off-9-large {
    margin-left: 75%;
  }
  .row > .col-10-large {
    width: 83.3333333333%;
  }
  .row > .off-10-large {
    margin-left: 83.3333333333%;
  }
  .row > .col-11-large {
    width: 91.6666666667%;
  }
  .row > .off-11-large {
    margin-left: 91.6666666667%;
  }
  .row > .col-12-large {
    width: 100%;
  }
  .row > .off-12-large {
    margin-left: 100%;
  }
  .row.gtr-0 {
    margin-top: 0;
    margin-left: 0em;
  }
  .row.gtr-0 > * {
    padding: 0 0 0 0em;
  }
  .row.gtr-0.gtr-uniform {
    margin-top: 0em;
  }
  .row.gtr-0.gtr-uniform > * {
    padding-top: 0em;
  }
  .row.gtr-25 {
    margin-top: 0;
    margin-left: -0.375em;
  }
  .row.gtr-25 > * {
    padding: 0 0 0 0.375em;
  }
  .row.gtr-25.gtr-uniform {
    margin-top: -0.375em;
  }
  .row.gtr-25.gtr-uniform > * {
    padding-top: 0.375em;
  }
  .row.gtr-50 {
    margin-top: 0;
    margin-left: -0.75em;
  }
  .row.gtr-50 > * {
    padding: 0 0 0 0.75em;
  }
  .row.gtr-50.gtr-uniform {
    margin-top: -0.75em;
  }
  .row.gtr-50.gtr-uniform > * {
    padding-top: 0.75em;
  }
  .row {
    margin-top: 0;
    margin-left: -1.5em;
  }
  .row > * {
    padding: 0 0 0 1.5em;
  }
  .row.gtr-uniform {
    margin-top: -1.5em;
  }
  .row.gtr-uniform > * {
    padding-top: 1.5em;
  }
  .row.gtr-150 {
    margin-top: 0;
    margin-left: -2.25em;
  }
  .row.gtr-150 > * {
    padding: 0 0 0 2.25em;
  }
  .row.gtr-150.gtr-uniform {
    margin-top: -2.25em;
  }
  .row.gtr-150.gtr-uniform > * {
    padding-top: 2.25em;
  }
  .row.gtr-200 {
    margin-top: 0;
    margin-left: -3em;
  }
  .row.gtr-200 > * {
    padding: 0 0 0 3em;
  }
  .row.gtr-200.gtr-uniform {
    margin-top: -3em;
  }
  .row.gtr-200.gtr-uniform > * {
    padding-top: 3em;
  }
}
@media screen and (max-width: 980px) {
  .row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
        -ms-flex-align: stretch;
            align-items: stretch;
  }
  .row > * {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
  }
  .row.gtr-uniform > * > :last-child {
    margin-bottom: 0;
  }
  .row.aln-left {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
  .row.aln-center {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .row.aln-right {
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
        -ms-flex-pack: end;
            justify-content: flex-end;
  }
  .row.aln-top {
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
  .row.aln-middle {
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .row.aln-bottom {
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
        -ms-flex-align: end;
            align-items: flex-end;
  }
  .row > .imp-medium {
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
        -ms-flex-order: -1;
            order: -1;
  }
  .row > .col-1-medium {
    width: 8.3333333333%;
  }
  .row > .off-1-medium {
    margin-left: 8.3333333333%;
  }
  .row > .col-2-medium {
    width: 16.6666666667%;
  }
  .row > .off-2-medium {
    margin-left: 16.6666666667%;
  }
  .row > .col-3-medium {
    width: 25%;
  }
  .row > .off-3-medium {
    margin-left: 25%;
  }
  .row > .col-4-medium {
    width: 33.3333333333%;
  }
  .row > .off-4-medium {
    margin-left: 33.3333333333%;
  }
  .row > .col-5-medium {
    width: 41.6666666667%;
  }
  .row > .off-5-medium {
    margin-left: 41.6666666667%;
  }
  .row > .col-6-medium {
    width: 50%;
  }
  .row > .off-6-medium {
    margin-left: 50%;
  }
  .row > .col-7-medium {
    width: 58.3333333333%;
  }
  .row > .off-7-medium {
    margin-left: 58.3333333333%;
  }
  .row > .col-8-medium {
    width: 66.6666666667%;
  }
  .row > .off-8-medium {
    margin-left: 66.6666666667%;
  }
  .row > .col-9-medium {
    width: 75%;
  }
  .row > .off-9-medium {
    margin-left: 75%;
  }
  .row > .col-10-medium {
    width: 83.3333333333%;
  }
  .row > .off-10-medium {
    margin-left: 83.3333333333%;
  }
  .row > .col-11-medium {
    width: 91.6666666667%;
  }
  .row > .off-11-medium {
    margin-left: 91.6666666667%;
  }
  .row > .col-12-medium {
    width: 100%;
  }
  .row > .off-12-medium {
    margin-left: 100%;
  }
  .row.gtr-0 {
    margin-top: 0;
    margin-left: 0em;
  }
  .row.gtr-0 > * {
    padding: 0 0 0 0em;
  }
  .row.gtr-0.gtr-uniform {
    margin-top: 0em;
  }
  .row.gtr-0.gtr-uniform > * {
    padding-top: 0em;
  }
  .row.gtr-25 {
    margin-top: 0;
    margin-left: -0.375em;
  }
  .row.gtr-25 > * {
    padding: 0 0 0 0.375em;
  }
  .row.gtr-25.gtr-uniform {
    margin-top: -0.375em;
  }
  .row.gtr-25.gtr-uniform > * {
    padding-top: 0.375em;
  }
  .row.gtr-50 {
    margin-top: 0;
    margin-left: -0.75em;
  }
  .row.gtr-50 > * {
    padding: 0 0 0 0.75em;
  }
  .row.gtr-50.gtr-uniform {
    margin-top: -0.75em;
  }
  .row.gtr-50.gtr-uniform > * {
    padding-top: 0.75em;
  }
  .row {
    margin-top: 0;
    margin-left: -1.5em;
  }
  .row > * {
    padding: 0 0 0 1.5em;
  }
  .row.gtr-uniform {
    margin-top: -1.5em;
  }
  .row.gtr-uniform > * {
    padding-top: 1.5em;
  }
  .row.gtr-150 {
    margin-top: 0;
    margin-left: -2.25em;
  }
  .row.gtr-150 > * {
    padding: 0 0 0 2.25em;
  }
  .row.gtr-150.gtr-uniform {
    margin-top: -2.25em;
  }
  .row.gtr-150.gtr-uniform > * {
    padding-top: 2.25em;
  }
  .row.gtr-200 {
    margin-top: 0;
    margin-left: -3em;
  }
  .row.gtr-200 > * {
    padding: 0 0 0 3em;
  }
  .row.gtr-200.gtr-uniform {
    margin-top: -3em;
  }
  .row.gtr-200.gtr-uniform > * {
    padding-top: 3em;
  }
}
@media screen and (max-width: 736px) {
  .row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
        -ms-flex-align: stretch;
            align-items: stretch;
  }
  .row > * {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
  }
  .row.gtr-uniform > * > :last-child {
    margin-bottom: 0;
  }
  .row.aln-left {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
  .row.aln-center {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .row.aln-right {
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
        -ms-flex-pack: end;
            justify-content: flex-end;
  }
  .row.aln-top {
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
  .row.aln-middle {
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .row.aln-bottom {
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
        -ms-flex-align: end;
            align-items: flex-end;
  }
  .row > .imp-small {
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
        -ms-flex-order: -1;
            order: -1;
  }
  .row > .col-1-small {
    width: 8.3333333333%;
  }
  .row > .off-1-small {
    margin-left: 8.3333333333%;
  }
  .row > .col-2-small {
    width: 16.6666666667%;
  }
  .row > .off-2-small {
    margin-left: 16.6666666667%;
  }
  .row > .col-3-small {
    width: 25%;
  }
  .row > .off-3-small {
    margin-left: 25%;
  }
  .row > .col-4-small {
    width: 33.3333333333%;
  }
  .row > .off-4-small {
    margin-left: 33.3333333333%;
  }
  .row > .col-5-small {
    width: 41.6666666667%;
  }
  .row > .off-5-small {
    margin-left: 41.6666666667%;
  }
  .row > .col-6-small {
    width: 50%;
  }
  .row > .off-6-small {
    margin-left: 50%;
  }
  .row > .col-7-small {
    width: 58.3333333333%;
  }
  .row > .off-7-small {
    margin-left: 58.3333333333%;
  }
  .row > .col-8-small {
    width: 66.6666666667%;
  }
  .row > .off-8-small {
    margin-left: 66.6666666667%;
  }
  .row > .col-9-small {
    width: 75%;
  }
  .row > .off-9-small {
    margin-left: 75%;
  }
  .row > .col-10-small {
    width: 83.3333333333%;
  }
  .row > .off-10-small {
    margin-left: 83.3333333333%;
  }
  .row > .col-11-small {
    width: 91.6666666667%;
  }
  .row > .off-11-small {
    margin-left: 91.6666666667%;
  }
  .row > .col-12-small {
    width: 100%;
  }
  .row > .off-12-small {
    margin-left: 100%;
  }
  .row.gtr-0 {
    margin-top: 0;
    margin-left: 0em;
  }
  .row.gtr-0 > * {
    padding: 0 0 0 0em;
  }
  .row.gtr-0.gtr-uniform {
    margin-top: 0em;
  }
  .row.gtr-0.gtr-uniform > * {
    padding-top: 0em;
  }
  .row.gtr-25 {
    margin-top: 0;
    margin-left: -0.3125em;
  }
  .row.gtr-25 > * {
    padding: 0 0 0 0.3125em;
  }
  .row.gtr-25.gtr-uniform {
    margin-top: -0.3125em;
  }
  .row.gtr-25.gtr-uniform > * {
    padding-top: 0.3125em;
  }
  .row.gtr-50 {
    margin-top: 0;
    margin-left: -0.625em;
  }
  .row.gtr-50 > * {
    padding: 0 0 0 0.625em;
  }
  .row.gtr-50.gtr-uniform {
    margin-top: -0.625em;
  }
  .row.gtr-50.gtr-uniform > * {
    padding-top: 0.625em;
  }
  .row {
    margin-top: 0;
    margin-left: -1.25em;
  }
  .row > * {
    padding: 0 0 0 1.25em;
  }
  .row.gtr-uniform {
    margin-top: -1.25em;
  }
  .row.gtr-uniform > * {
    padding-top: 1.25em;
  }
  .row.gtr-150 {
    margin-top: 0;
    margin-left: -1.875em;
  }
  .row.gtr-150 > * {
    padding: 0 0 0 1.875em;
  }
  .row.gtr-150.gtr-uniform {
    margin-top: -1.875em;
  }
  .row.gtr-150.gtr-uniform > * {
    padding-top: 1.875em;
  }
  .row.gtr-200 {
    margin-top: 0;
    margin-left: -2.5em;
  }
  .row.gtr-200 > * {
    padding: 0 0 0 2.5em;
  }
  .row.gtr-200.gtr-uniform {
    margin-top: -2.5em;
  }
  .row.gtr-200.gtr-uniform > * {
    padding-top: 2.5em;
  }
}
@media screen and (max-width: 480px) {
  .row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
        -ms-flex-align: stretch;
            align-items: stretch;
  }
  .row > * {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
  }
  .row.gtr-uniform > * > :last-child {
    margin-bottom: 0;
  }
  .row.aln-left {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
  .row.aln-center {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .row.aln-right {
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
        -ms-flex-pack: end;
            justify-content: flex-end;
  }
  .row.aln-top {
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
  .row.aln-middle {
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .row.aln-bottom {
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
        -ms-flex-align: end;
            align-items: flex-end;
  }
  .row > .imp-xsmall {
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
        -ms-flex-order: -1;
            order: -1;
  }
  .row > .col-1-xsmall {
    width: 8.3333333333%;
  }
  .row > .off-1-xsmall {
    margin-left: 8.3333333333%;
  }
  .row > .col-2-xsmall {
    width: 16.6666666667%;
  }
  .row > .off-2-xsmall {
    margin-left: 16.6666666667%;
  }
  .row > .col-3-xsmall {
    width: 25%;
  }
  .row > .off-3-xsmall {
    margin-left: 25%;
  }
  .row > .col-4-xsmall {
    width: 33.3333333333%;
  }
  .row > .off-4-xsmall {
    margin-left: 33.3333333333%;
  }
  .row > .col-5-xsmall {
    width: 41.6666666667%;
  }
  .row > .off-5-xsmall {
    margin-left: 41.6666666667%;
  }
  .row > .col-6-xsmall {
    width: 50%;
  }
  .row > .off-6-xsmall {
    margin-left: 50%;
  }
  .row > .col-7-xsmall {
    width: 58.3333333333%;
  }
  .row > .off-7-xsmall {
    margin-left: 58.3333333333%;
  }
  .row > .col-8-xsmall {
    width: 66.6666666667%;
  }
  .row > .off-8-xsmall {
    margin-left: 66.6666666667%;
  }
  .row > .col-9-xsmall {
    width: 75%;
  }
  .row > .off-9-xsmall {
    margin-left: 75%;
  }
  .row > .col-10-xsmall {
    width: 83.3333333333%;
  }
  .row > .off-10-xsmall {
    margin-left: 83.3333333333%;
  }
  .row > .col-11-xsmall {
    width: 91.6666666667%;
  }
  .row > .off-11-xsmall {
    margin-left: 91.6666666667%;
  }
  .row > .col-12-xsmall {
    width: 100%;
  }
  .row > .off-12-xsmall {
    margin-left: 100%;
  }
  .row.gtr-0 {
    margin-top: 0;
    margin-left: 0em;
  }
  .row.gtr-0 > * {
    padding: 0 0 0 0em;
  }
  .row.gtr-0.gtr-uniform {
    margin-top: 0em;
  }
  .row.gtr-0.gtr-uniform > * {
    padding-top: 0em;
  }
  .row.gtr-25 {
    margin-top: 0;
    margin-left: -0.3125em;
  }
  .row.gtr-25 > * {
    padding: 0 0 0 0.3125em;
  }
  .row.gtr-25.gtr-uniform {
    margin-top: -0.3125em;
  }
  .row.gtr-25.gtr-uniform > * {
    padding-top: 0.3125em;
  }
  .row.gtr-50 {
    margin-top: 0;
    margin-left: -0.625em;
  }
  .row.gtr-50 > * {
    padding: 0 0 0 0.625em;
  }
  .row.gtr-50.gtr-uniform {
    margin-top: -0.625em;
  }
  .row.gtr-50.gtr-uniform > * {
    padding-top: 0.625em;
  }
  .row {
    margin-top: 0;
    margin-left: -1.25em;
  }
  .row > * {
    padding: 0 0 0 1.25em;
  }
  .row.gtr-uniform {
    margin-top: -1.25em;
  }
  .row.gtr-uniform > * {
    padding-top: 1.25em;
  }
  .row.gtr-150 {
    margin-top: 0;
    margin-left: -1.875em;
  }
  .row.gtr-150 > * {
    padding: 0 0 0 1.875em;
  }
  .row.gtr-150.gtr-uniform {
    margin-top: -1.875em;
  }
  .row.gtr-150.gtr-uniform > * {
    padding-top: 1.875em;
  }
  .row.gtr-200 {
    margin-top: 0;
    margin-left: -2.5em;
  }
  .row.gtr-200 > * {
    padding: 0 0 0 2.5em;
  }
  .row.gtr-200.gtr-uniform {
    margin-top: -2.5em;
  }
  .row.gtr-200.gtr-uniform > * {
    padding-top: 2.5em;
  }
}

/* Box */
.box {
  -webkit-border-radius: 4px;
          border-radius: 4px;
  border: solid 1px;
  margin-bottom: 2em;
  padding: 1.5em;
}
.box > :last-child,
.box > :last-child > :last-child,
.box > :last-child > :last-child > :last-child {
  margin-bottom: 0;
}
.box.alt {
  border: 0;
  -webkit-border-radius: 0;
          border-radius: 0;
  padding: 0;
}

.box {
  border-color: rgba(99, 116, 133, 0.25);
}

/* Button */
input[type=submit],
input[type=reset],
input[type=button],
button,
.button {
  -moz-appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  -webkit-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  -o-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  -webkit-border-radius: 4px;
          border-radius: 4px;
  border: 0;
  cursor: pointer;
  display: inline-block;
  font-size: 0.8em;
  font-weight: 700;
  height: 3.5em;
  line-height: 3.5em;
  padding: 0 2.25em;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap;
}
input[type=submit].icon,
input[type=reset].icon,
input[type=button].icon,
button.icon,
.button.icon {
  padding-left: 1.35em;
}
input[type=submit].icon:before,
input[type=reset].icon:before,
input[type=button].icon:before,
button.icon:before,
.button.icon:before {
  font-size: 0.95em;
  margin-right: 0.5em;
}
input[type=submit].fit,
input[type=reset].fit,
input[type=button].fit,
button.fit,
.button.fit {
  width: 100%;
}
input[type=submit].small,
input[type=reset].small,
input[type=button].small,
button.small,
.button.small {
  font-size: 0.6em;
}
input[type=submit].large,
input[type=reset].large,
input[type=button].large,
button.large,
.button.large {
  font-size: 0.95em;
}
input[type=submit].disabled, input[type=submit]:disabled,
input[type=reset].disabled,
input[type=reset]:disabled,
input[type=button].disabled,
input[type=button]:disabled,
button.disabled,
button:disabled,
.button.disabled,
.button:disabled {
  pointer-events: none;
  opacity: 0.25;
}
@media screen and (max-width: 480px) {
  input[type=submit],
input[type=reset],
input[type=button],
button,
.button {
    padding: 0;
  }
}

input[type=submit],
input[type=reset],
input[type=button],
button,
.button {
  background-color: transparent;
  -webkit-box-shadow: inset 0 0 0 1px rgba(99, 116, 133, 0.25);
          box-shadow: inset 0 0 0 1px rgba(99, 116, 133, 0.25);
  color: #637485 !important;
}
input[type=submit]:hover, input[type=submit]:focus,
input[type=reset]:hover,
input[type=reset]:focus,
input[type=button]:hover,
input[type=button]:focus,
button:hover,
button:focus,
.button:hover,
.button:focus {
  background-color: rgba(99, 116, 133, 0.075);
}
input[type=submit]:active,
input[type=reset]:active,
input[type=button]:active,
button:active,
.button:active {
  background-color: rgba(99, 116, 133, 0.175);
}
input[type=submit].icon:before,
input[type=reset].icon:before,
input[type=button].icon:before,
button.icon:before,
.button.icon:before {
  color: #bbb;
}
input[type=submit].primary,
input[type=reset].primary,
input[type=button].primary,
button.primary,
.button.primary {
  -webkit-box-shadow: none;
          box-shadow: none;
  background-color: #39a288;
  color: #ffffff !important;
}
input[type=submit].primary.icon:before,
input[type=reset].primary.icon:before,
input[type=button].primary.icon:before,
button.primary.icon:before,
.button.primary.icon:before {
  color: #ffffff !important;
}
input[type=submit].primary:hover, input[type=submit].primary:focus,
input[type=reset].primary:hover,
input[type=reset].primary:focus,
input[type=button].primary:hover,
input[type=button].primary:focus,
button.primary:hover,
button.primary:focus,
.button.primary:hover,
.button.primary:focus {
  background-color: #40b598;
}
input[type=submit].primary:active,
input[type=reset].primary:active,
input[type=button].primary:active,
button.primary:active,
.button.primary:active {
  background-color: #328f78;
}

/* Form */
form {
  margin: 0 0 2em 0;
}
form > :last-child {
  margin-bottom: 0;
}
form > .fields {
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: -webkit-calc(100% + 3em);
  width: calc(100% + 3em);
  margin: -1.5em 0 2em -1.5em;
}
form > .fields > .field {
  -moz-flex-grow: 0;
  -webkit-flex-grow: 0;
  -ms-flex-grow: 0;
  -webkit-box-flex: 0;
      -ms-flex-positive: 0;
          flex-grow: 0;
  -webkit-flex-shrink: 0;
  -ms-flex-shrink: 0;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  padding: 1.5em 0 0 1.5em;
  width: -webkit-calc(100% - 1.5em);
  width: calc(100% - 1.5em);
}
form > .fields > .field.half {
  width: -webkit-calc(50% - 0.75em);
  width: calc(50% - 0.75em);
}
form > .fields > .field.third {
  width: -webkit-calc(100%/3 - 0.5em);
  width: calc(100%/3 - 0.5em);
}
form > .fields > .field.quarter {
  width: -webkit-calc(25% - 0.375em);
  width: calc(25% - 0.375em);
}
@media screen and (max-width: 480px) {
  form > .fields {
    width: -webkit-calc(100% + 3em);
    width: calc(100% + 3em);
    margin: -1.5em 0 2em -1.5em;
  }
  form > .fields > .field {
    padding: 1.5em 0 0 1.5em;
    width: -webkit-calc(100% - 1.5em);
    width: calc(100% - 1.5em);
  }
  form > .fields > .field.half {
    width: -webkit-calc(100% - 1.5em);
    width: calc(100% - 1.5em);
  }
  form > .fields > .field.third {
    width: -webkit-calc(100% - 1.5em);
    width: calc(100% - 1.5em);
  }
  form > .fields > .field.quarter {
    width: -webkit-calc(100% - 1.5em);
    width: calc(100% - 1.5em);
  }
}

label {
  display: block;
  font-size: 0.9em;
  font-weight: 700;
  margin: 0 0 1em 0;
}

input[type=text],
input[type=password],
input[type=email],
select,
textarea {
  -moz-appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  border: none;
  border: solid 1px;
  color: inherit;
  display: block;
  outline: 0;
  padding: 0 1em;
  text-decoration: none;
  width: 100%;
}
input[type=text]:invalid,
input[type=password]:invalid,
input[type=email]:invalid,
select:invalid,
textarea:invalid {
  -webkit-box-shadow: none;
          box-shadow: none;
}

select {
  -webkit-background-size: 1.25rem 1.25rem;
          background-size: 1.25rem;
  background-repeat: no-repeat;
  background-position: -webkit-calc(100% - 1rem) center;
  background-position: calc(100% - 1rem) center;
  height: 3em;
  padding-right: 3em;
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;
}
select:focus::-ms-value {
  background-color: transparent;
}
select::-ms-expand {
  display: none;
}

input[type=text],
input[type=password],
input[type=email],
select {
  height: 3em;
}

textarea {
  padding: 0.75em 1em;
}

input[type=checkbox],
input[type=radio] {
  -moz-appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  display: block;
  float: left;
  margin-right: -2em;
  opacity: 0;
  width: 1em;
  z-index: -1;
}
input[type=checkbox] + label,
input[type=radio] + label {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  font-size: 1em;
  font-weight: 400;
  padding-left: 2.55em;
  padding-right: 0.75em;
  position: relative;
}
input[type=checkbox] + label:before,
input[type=radio] + label:before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  text-transform: none !important;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}
input[type=checkbox] + label:before,
input[type=radio] + label:before {
  -webkit-border-radius: 4px;
          border-radius: 4px;
  border: solid 1px;
  content: "";
  display: inline-block;
  font-size: 0.8em;
  height: 1.8em;
  left: 0;
  line-height: 1.725em;
  position: absolute;
  text-align: center;
  top: 0;
  width: 1.8em;
}
input[type=checkbox]:checked + label:before,
input[type=radio]:checked + label:before {
  content: "";
}

input[type=checkbox] + label:before {
  -webkit-border-radius: 4px;
          border-radius: 4px;
}

input[type=radio] + label:before {
  -webkit-border-radius: 100%;
          border-radius: 100%;
}

::-webkit-input-placeholder {
  opacity: 1;
}

:-moz-placeholder {
  opacity: 1;
}

::-moz-placeholder {
  opacity: 1;
}

:-ms-input-placeholder {
  opacity: 1;
}

label {
  color: #637485;
}

input[type=text],
input[type=password],
input[type=email],
select,
textarea {
  background-color: rgba(99, 116, 133, 0.075);
  border-color: transparent;
}
input[type=text]:focus,
input[type=password]:focus,
input[type=email]:focus,
select:focus,
textarea:focus {
  border-color: #39a288;
  -webkit-box-shadow: 0 0 0 1px #39a288;
          box-shadow: 0 0 0 1px #39a288;
}

select {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' preserveAspectRatio='none' viewBox='0 0 40 40'%3E%3Cpath d='M9.4,12.3l10.4,10.4l10.4-10.4c0.2-0.2,0.5-0.4,0.9-0.4c0.3,0,0.6,0.1,0.9,0.4l3.3,3.3c0.2,0.2,0.4,0.5,0.4,0.9 c0,0.4-0.1,0.6-0.4,0.9L20.7,31.9c-0.2,0.2-0.5,0.4-0.9,0.4c-0.3,0-0.6-0.1-0.9-0.4L4.3,17.3c-0.2-0.2-0.4-0.5-0.4-0.9 c0-0.4,0.1-0.6,0.4-0.9l3.3-3.3c0.2-0.2,0.5-0.4,0.9-0.4S9.1,12.1,9.4,12.3z' fill='rgba(99, 116, 133, 0.25)' /%3E%3C/svg%3E");
}
select option {
  color: #637485;
  background: #fff;
}

input[type=checkbox] + label,
input[type=radio] + label {
  color: #637485;
}
input[type=checkbox] + label:before,
input[type=radio] + label:before {
  background: rgba(99, 116, 133, 0.075);
  border-color: rgba(99, 116, 133, 0.25);
}
input[type=checkbox]:checked + label:before,
input[type=radio]:checked + label:before {
  background-color: #39a288;
  border-color: #39a288;
  color: #ffffff;
}
input[type=checkbox]:focus + label:before,
input[type=radio]:focus + label:before {
  border-color: #39a288;
  -webkit-box-shadow: 0 0 0 1px #39a288;
          box-shadow: 0 0 0 1px #39a288;
}

::-webkit-input-placeholder {
  color: #bbb !important;
}

:-moz-placeholder {
  color: #bbb !important;
}

::-moz-placeholder {
  color: #bbb !important;
}

:-ms-input-placeholder {
  color: #bbb !important;
}

.formerize-placeholder {
  color: #bbb !important;
}

/* Icon */
.icon {
  text-decoration: none;
  border-bottom: none;
  position: relative;
}
.icon:before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  text-transform: none !important;
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.icon.solid:before {
  font-weight: 900;
}
.icon.brands:before {
  font-family: "Font Awesome 5 Brands";
}
.icon > .label {
  display: none;
}

/* Image */
.image {
  border: 0;
  display: inline-block;
  position: relative;
}
.image img {
  display: block;
}
.image.left, .image.right {
  max-width: 40%;
}
.image.left img, .image.right img {
  width: 100%;
}
.image.left {
  float: left;
  margin: 0 1.5em 1em 0;
  top: 0.25em;
}
.image.right {
  float: right;
  margin: 0 0 1em 1.5em;
  top: 0.25em;
}
.image.fit {
  display: block;
  margin: 0 0 2em 0;
  width: 100%;
}
.image.fit img {
  width: 100%;
}
.image.main {
  display: block;
  margin: 0 0 3em 0;
  width: 100%;
}
.image.main img {
  width: 100%;
}
@media screen and (max-width: 736px) {
  .image.main {
    margin: 0 0 2em 0;
  }
}

/* List */
ol {
  list-style: decimal;
  margin: 0 0 2em 0;
  padding-left: 1.25em;
}
ol li {
  padding-left: 0.25em;
}

ul {
  list-style: disc;
  margin: 0 0 2em 0;
  padding-left: 1em;
}
ul li {
  padding-left: 0.5em;
}
ul.alt {
  list-style: none;
  padding-left: 0;
}
ul.alt li {
  border-top: solid 1px;
  padding: 0.5em 0;
}
ul.alt li:first-child {
  border-top: 0;
  padding-top: 0;
}

dl {
  margin: 0 0 2em 0;
}
dl dt {
  display: block;
  font-weight: 700;
  margin: 0 0 1em 0;
}
dl dd {
  margin-left: 2em;
}

ul.alt li {
  border-top-color: rgba(99, 116, 133, 0.25);
}

/* Actions */
ul.actions {
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  cursor: default;
  list-style: none;
  margin-left: -1em;
  padding-left: 0;
}
ul.actions li {
  padding: 0 0 0 1em;
  vertical-align: middle;
}
ul.actions.special {
  -moz-justify-content: center;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
  margin-left: 0;
}
ul.actions.special li:first-child {
  padding-left: 0;
}
ul.actions.stacked {
  -moz-flex-direction: column;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  margin-left: 0;
}
ul.actions.stacked li {
  padding: 1.3em 0 0 0;
}
ul.actions.stacked li:first-child {
  padding-top: 0;
}
ul.actions.fit {
  width: -webkit-calc(100% + 1em);
  width: calc(100% + 1em);
}
ul.actions.fit li {
  -moz-flex-grow: 1;
  -webkit-flex-grow: 1;
  -ms-flex-grow: 1;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  -webkit-flex-shrink: 1;
  -ms-flex-shrink: 1;
  -ms-flex-negative: 1;
      flex-shrink: 1;
  width: 100%;
}
ul.actions.fit li > * {
  width: 100%;
}
ul.actions.fit.stacked {
  width: 100%;
}
@media screen and (max-width: 480px) {
  ul.actions:not(.fixed) {
    -moz-flex-direction: column;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    margin-left: 0;
    width: 100% !important;
  }
  ul.actions:not(.fixed) li {
    -moz-flex-grow: 1;
    -webkit-flex-grow: 1;
    -ms-flex-grow: 1;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    -webkit-flex-shrink: 1;
    -ms-flex-shrink: 1;
    -ms-flex-negative: 1;
        flex-shrink: 1;
    padding: 1em 0 0 0;
    text-align: center;
    width: 100%;
  }
  ul.actions:not(.fixed) li > * {
    width: 100%;
  }
  ul.actions:not(.fixed) li:first-child {
    padding-top: 0;
  }
  ul.actions:not(.fixed) li input[type=submit],
ul.actions:not(.fixed) li input[type=reset],
ul.actions:not(.fixed) li input[type=button],
ul.actions:not(.fixed) li button,
ul.actions:not(.fixed) li .button {
    width: 100%;
  }
  ul.actions:not(.fixed) li input[type=submit].icon:before,
ul.actions:not(.fixed) li input[type=reset].icon:before,
ul.actions:not(.fixed) li input[type=button].icon:before,
ul.actions:not(.fixed) li button.icon:before,
ul.actions:not(.fixed) li .button.icon:before {
    margin-left: -0.5em;
  }
}

/* Icons */
ul.icons {
  cursor: default;
  list-style: none;
  padding-left: 0;
}
ul.icons li {
  display: inline-block;
  padding: 0 1em 0 0;
}
ul.icons li:last-child {
  padding-right: 0;
}
ul.icons li .icon:before {
  font-size: 2em;
}

/* Section/Article */
section.special, article.special {
  text-align: center;
}

header p {
  position: relative;
  margin: 0 0 1.5em 0;
}
header h2 + p {
  font-size: 1.25em;
  margin-top: -1em;
}
header h3 + p {
  font-size: 1.1em;
  margin-top: -0.8em;
}
header h4 + p,
header h5 + p,
header h6 + p {
  font-size: 0.9em;
  margin-top: -0.6em;
}
header.major {
  margin-bottom: 2.7em;
  padding-bottom: 2.7em;
  position: relative;
}
header.major h1, header.major h2, header.major h3 {
  margin-bottom: 0;
}
header.major p {
  font-weight: 700;
  margin-bottom: 0.25em;
  text-transform: uppercase;
}
header.major:after {
  bottom: 0;
  content: "";
  height: 1px;
  left: 0;
  position: absolute;
  width: 7em;
}
header.major.alt {
  margin-bottom: 0;
}
@media screen and (max-width: 1280px) {
  header.major.alt {
    margin-bottom: 2.7em;
  }
}
@media screen and (max-width: 736px) {
  header.major {
    margin-bottom: 1.8em;
    padding-bottom: 1.3em;
  }
  header.major.alt {
    margin-bottom: 1.8em;
  }
}

header p {
  color: #bbb;
}
header.major p {
  color: #39a288;
}
header.major:after {
  background-color: rgba(99, 116, 133, 0.25);
}

/* Table */
.table-wrapper {
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
}

table {
  margin: 0 0 2em 0;
  width: 100%;
}
table tbody tr {
  border: solid 1px;
  border-left: 0;
  border-right: 0;
}
table td {
  padding: 0.75em 0.75em;
}
table th {
  font-size: 0.9em;
  font-weight: 700;
  padding: 0 0.75em 0.75em 0.75em;
  text-align: left;
}
table thead {
  border-bottom: solid 2px;
}
table tfoot {
  border-top: solid 2px;
}
table.alt {
  border-collapse: separate;
}
table.alt tbody tr td {
  border: solid 1px;
  border-left-width: 0;
  border-top-width: 0;
}
table.alt tbody tr td:first-child {
  border-left-width: 1px;
}
table.alt tbody tr:first-child td {
  border-top-width: 1px;
}
table.alt thead {
  border-bottom: 0;
}
table.alt tfoot {
  border-top: 0;
}

table tbody tr {
  border-color: rgba(99, 116, 133, 0.25);
}
table tbody tr:nth-child(2n + 1) {
  background-color: rgba(99, 116, 133, 0.075);
}
table th {
  color: #637485;
}
table thead {
  border-bottom-color: rgba(99, 116, 133, 0.25);
}
table tfoot {
  border-top-color: rgba(99, 116, 133, 0.25);
}
table.alt tbody tr td {
  border-color: rgba(99, 116, 133, 0.25);
}

/* Spotlights */
.spotlights {
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -moz-justify-content: center;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-bottom: 4em;
}
.spotlights article {
  padding: 3.5em 4em 1.5em 4em;
  -webkit-box-shadow: 0 1em 5em rgba(0, 0, 0, 0.05);
          box-shadow: 0 1em 5em rgba(0, 0, 0, 0.05);
  margin-bottom: 3em;
  width: 48%;
}
.spotlights article:nth-child(even) {
  margin-left: 4%;
}
.spotlights article header.major:after {
  margin-left: -4em;
}
@media screen and (max-width: 980px) {
  .spotlights article {
    padding: 2em 2em 0.1em 2em;
    width: 100%;
  }
  .spotlights article:nth-child(even) {
    margin-left: 0;
  }
  .spotlights article header.major:after {
    margin-left: -2em;
  }
}
@media screen and (max-width: 736px) {
  .spotlights {
    margin-bottom: 2em;
  }
}
@media screen and (max-width: 480px) {
  .spotlights article {
    padding: 1.5em 1.5em 0.1em 1.5em;
  }
  .spotlights article header.major:after {
    margin-left: -1.5em;
  }
}

.spotlights article {
  background-color: #ffffff;
}

/* Features */
.features {
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -moz-justify-content: center;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  list-style-type: none;
  padding-left: 0;
}
.features li {
  margin-bottom: 2em;
  padding: 0 1em;
  width: 25%;
}
.features .icon {
  -webkit-border-radius: 100%;
          border-radius: 100%;
  -webkit-box-shadow: -0.5em 0.5em 1em rgba(0, 0, 0, 0.05);
          box-shadow: -0.5em 0.5em 1em rgba(0, 0, 0, 0.05);
  display: block;
  font-size: 2.75em;
  height: 3.75em;
  line-height: 3.75em;
  margin: 0 auto 0.7em;
  text-align: center;
  width: 3.75em;
}
.features h4 {
  font-weight: 700;
  margin-bottom: 0.5em;
  text-transform: uppercase;
}
@media screen and (max-width: 980px) {
  .features li {
    width: 50%;
  }
}
@media screen and (max-width: 736px) {
  .features .icon {
    font-size: 2em;
  }
}
@media screen and (max-width: 480px) {
  .features li {
    margin-bottom: 1em;
    width: 100%;
  }
}

/* Posts */
.posts {
  margin-bottom: 4em;
}
.posts article {
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -moz-align-items: center;
  -webkit-align-items: center;
  -ms-align-items: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 2em;
  width: 100%;
}
.posts article .content {
  -ms-flex: 1;
}
.posts article .image {
  -moz-order: 99;
  -webkit-order: 99;
  -ms-order: 99;
  -webkit-box-ordinal-group: 100;
      -ms-flex-order: 99;
          order: 99;
  margin: 0 0 2em 2em;
}
.posts article.alt .image {
  -moz-order: -1;
  -webkit-order: -1;
  -ms-order: -1;
  -webkit-box-ordinal-group: 0;
      -ms-flex-order: -1;
          order: -1;
  margin: 0 2em 2em 0;
}
@media screen and (max-width: 1280px) {
  .posts .content {
    -webkit-flex-shrink: 0;
    -ms-flex-shrink: 0;
    -ms-flex-negative: 0;
        flex-shrink: 0;
    width: 33%;
  }
  .posts .image img {
    max-width: 100%;
  }
}
@media screen and (max-width: 980px) {
  .posts article {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .posts article .content {
    width: 100%;
  }
  .posts article .image {
    -moz-order: -1;
    -webkit-order: -1;
    -ms-order: -1;
    -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
            order: -1;
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
}
@media screen and (max-width: 736px) {
  .posts {
    margin-bottom: 2em;
  }
}

/* Timeline */
.timeline > section {
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-left: solid 1px;
  position: relative;
}
.timeline > section:before {
  position: absolute;
  content: "";
  -webkit-border-radius: 100%;
          border-radius: 100%;
  width: 1em;
  height: 1em;
  left: -0.5em;
  top: 0;
}
.timeline > section:last-child {
  border-left: 0;
}
.timeline header {
  -webkit-flex-shrink: 0;
  -ms-flex-shrink: 0;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  margin-bottom: 2em;
  padding: 0 2.5em;
  width: 25%;
}
.timeline header h4 {
  font-weight: 700;
  text-transform: uppercase;
}
.timeline header p {
  margin-bottom: 0.5em;
  font-weight: 700;
}
.timeline .image {
  margin-bottom: 2em;
  margin-right: 2.5em;
}
.timeline .content {
  margin-bottom: 2em;
  -ms-flex: 1;
}
.timeline .content p {
  margin-bottom: 1em;
}
@media screen and (max-width: 980px) {
  .timeline > section {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-left: 2em;
  }
  .timeline header {
    margin-bottom: 1em;
    padding: 0;
    width: 100%;
  }
}
@media screen and (max-width: 480px) {
  .timeline > section {
    padding-left: 1.5em;
  }
}

.timeline > section {
  border-color: #bbb;
}
.timeline > section:before {
  background-color: #bbb;
}
.timeline header p {
  color: #39a288;
}

/* Wrapper */
.wrapper {
  padding: 6em 0 4em 0;
  position: relative;
}
.wrapper:before, .wrapper:after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100' preserveAspectRatio='none'%3E%3Cpolygon points='0,0 0,100 100,100' style='fill:%23fff%3B' /%3E%3C/svg%3E");
}
.wrapper > .inner {
  margin: 0 auto;
  max-width: 75em;
  width: -webkit-calc(100% - 4em);
  width: calc(100% - 4em);
}
.wrapper:before, .wrapper:after {
  background-repeat: no-repeat;
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
  height: 6em;
  left: 0;
  position: absolute;
  width: 100%;
}
.wrapper:before {
  -webkit-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  transform: translateY(-100%);
  content: "";
  top: 0;
}
.wrapper.alt {
  padding-bottom: 1em;
}
.wrapper.alt:after {
  -webkit-transform: translateY(100%) rotate(180deg);
  -ms-transform: translateY(100%) rotate(180deg);
  transform: translateY(100%) rotate(180deg);
  bottom: 0;
  content: "";
}
.wrapper.style1 {
  background-color: #39a288;
  color: #ffffff;
}
.wrapper.style1 input, .wrapper.style1 select, .wrapper.style1 textarea {
  color: #ffffff;
}
.wrapper.style1 a {
  color: #ffffff;
}
.wrapper.style1 strong, .wrapper.style1 b {
  color: #ffffff;
}
.wrapper.style1 h1, .wrapper.style1 h2, .wrapper.style1 h3, .wrapper.style1 h4, .wrapper.style1 h5, .wrapper.style1 h6 {
  color: #ffffff;
}
.wrapper.style1 blockquote {
  border-left-color: rgba(255, 255, 255, 0.25);
}
.wrapper.style1 code {
  background: rgba(255, 255, 255, 0.065);
  border-color: rgba(255, 255, 255, 0.25);
}
.wrapper.style1 hr {
  border-bottom-color: rgba(255, 255, 255, 0.25);
}
.wrapper.style1 .box {
  border-color: rgba(255, 255, 255, 0.25);
}
.wrapper.style1 input[type=submit],
.wrapper.style1 input[type=reset],
.wrapper.style1 input[type=button],
.wrapper.style1 button,
.wrapper.style1 .button {
  background-color: transparent;
  -webkit-box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.25);
          box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.25);
  color: #ffffff !important;
}
.wrapper.style1 input[type=submit]:hover, .wrapper.style1 input[type=submit]:focus,
.wrapper.style1 input[type=reset]:hover,
.wrapper.style1 input[type=reset]:focus,
.wrapper.style1 input[type=button]:hover,
.wrapper.style1 input[type=button]:focus,
.wrapper.style1 button:hover,
.wrapper.style1 button:focus,
.wrapper.style1 .button:hover,
.wrapper.style1 .button:focus {
  background-color: rgba(255, 255, 255, 0.065);
}
.wrapper.style1 input[type=submit]:active,
.wrapper.style1 input[type=reset]:active,
.wrapper.style1 input[type=button]:active,
.wrapper.style1 button:active,
.wrapper.style1 .button:active {
  background-color: rgba(255, 255, 255, 0.165);
}
.wrapper.style1 input[type=submit].icon:before,
.wrapper.style1 input[type=reset].icon:before,
.wrapper.style1 input[type=button].icon:before,
.wrapper.style1 button.icon:before,
.wrapper.style1 .button.icon:before {
  color: rgba(255, 255, 255, 0.75);
}
.wrapper.style1 input[type=submit].primary,
.wrapper.style1 input[type=reset].primary,
.wrapper.style1 input[type=button].primary,
.wrapper.style1 button.primary,
.wrapper.style1 .button.primary {
  -webkit-box-shadow: none;
          box-shadow: none;
  background-color: #ffffff;
  color: #39a288 !important;
}
.wrapper.style1 input[type=submit].primary.icon:before,
.wrapper.style1 input[type=reset].primary.icon:before,
.wrapper.style1 input[type=button].primary.icon:before,
.wrapper.style1 button.primary.icon:before,
.wrapper.style1 .button.primary.icon:before {
  color: #39a288 !important;
}
.wrapper.style1 label {
  color: #ffffff;
}
.wrapper.style1 input[type=text],
.wrapper.style1 input[type=password],
.wrapper.style1 input[type=email],
.wrapper.style1 select,
.wrapper.style1 textarea {
  background-color: rgba(0, 0, 0, 0.065);
  border-color: transparent;
}
.wrapper.style1 input[type=text]:focus,
.wrapper.style1 input[type=password]:focus,
.wrapper.style1 input[type=email]:focus,
.wrapper.style1 select:focus,
.wrapper.style1 textarea:focus {
  border-color: #ffffff;
  -webkit-box-shadow: 0 0 0 1px #ffffff;
          box-shadow: 0 0 0 1px #ffffff;
}
.wrapper.style1 select {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' preserveAspectRatio='none' viewBox='0 0 40 40'%3E%3Cpath d='M9.4,12.3l10.4,10.4l10.4-10.4c0.2-0.2,0.5-0.4,0.9-0.4c0.3,0,0.6,0.1,0.9,0.4l3.3,3.3c0.2,0.2,0.4,0.5,0.4,0.9 c0,0.4-0.1,0.6-0.4,0.9L20.7,31.9c-0.2,0.2-0.5,0.4-0.9,0.4c-0.3,0-0.6-0.1-0.9-0.4L4.3,17.3c-0.2-0.2-0.4-0.5-0.4-0.9 c0-0.4,0.1-0.6,0.4-0.9l3.3-3.3c0.2-0.2,0.5-0.4,0.9-0.4S9.1,12.1,9.4,12.3z' fill='rgba(255, 255, 255, 0.25)' /%3E%3C/svg%3E");
}
.wrapper.style1 select option {
  color: #ffffff;
  background: #39a288;
}
.wrapper.style1 input[type=checkbox] + label,
.wrapper.style1 input[type=radio] + label {
  color: #ffffff;
}
.wrapper.style1 input[type=checkbox] + label:before,
.wrapper.style1 input[type=radio] + label:before {
  background: rgba(0, 0, 0, 0.065);
  border-color: rgba(255, 255, 255, 0.25);
}
.wrapper.style1 input[type=checkbox]:checked + label:before,
.wrapper.style1 input[type=radio]:checked + label:before {
  background-color: #ffffff;
  border-color: #ffffff;
  color: #39a288;
}
.wrapper.style1 input[type=checkbox]:focus + label:before,
.wrapper.style1 input[type=radio]:focus + label:before {
  border-color: #ffffff;
  -webkit-box-shadow: 0 0 0 1px #ffffff;
          box-shadow: 0 0 0 1px #ffffff;
}
.wrapper.style1 ::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.75) !important;
}
.wrapper.style1 :-moz-placeholder {
  color: rgba(255, 255, 255, 0.75) !important;
}
.wrapper.style1 ::-moz-placeholder {
  color: rgba(255, 255, 255, 0.75) !important;
}
.wrapper.style1 :-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.75) !important;
}
.wrapper.style1 .formerize-placeholder {
  color: rgba(255, 255, 255, 0.75) !important;
}
.wrapper.style1 ul.alt li {
  border-top-color: rgba(255, 255, 255, 0.25);
}
.wrapper.style1 header p {
  color: rgba(255, 255, 255, 0.75);
}
.wrapper.style1 header.major p {
  color: #ffffff;
}
.wrapper.style1 header.major:after {
  background-color: rgba(255, 255, 255, 0.25);
}
.wrapper.style1 table tbody tr {
  border-color: rgba(255, 255, 255, 0.25);
}
.wrapper.style1 table tbody tr:nth-child(2n + 1) {
  background-color: rgba(255, 255, 255, 0.065);
}
.wrapper.style1 table th {
  color: #ffffff;
}
.wrapper.style1 table thead {
  border-bottom-color: rgba(255, 255, 255, 0.25);
}
.wrapper.style1 table tfoot {
  border-top-color: rgba(255, 255, 255, 0.25);
}
.wrapper.style1 table.alt tbody tr td {
  border-color: rgba(255, 255, 255, 0.25);
}
.wrapper.style1 .spotlights article {
  background-color: rgba(255, 255, 255, 0.065);
}
.wrapper.style1 .timeline > section {
  border-color: rgba(255, 255, 255, 0.75);
}
.wrapper.style1 .timeline > section:before {
  background-color: rgba(255, 255, 255, 0.75);
}
.wrapper.style1 .timeline header p {
  color: #ffffff;
}
.wrapper.style1:before, .wrapper.style1:after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100' preserveAspectRatio='none'%3E%3Cpolygon points='0,0 0,100 100,100' style='fill:%2339a288%3B' /%3E%3C/svg%3E");
}
.wrapper.style2 {
  background-color: #f5f8fa;
  color: #637485;
}
.wrapper.style2 input, .wrapper.style2 select, .wrapper.style2 textarea {
  color: #637485;
}
.wrapper.style2 a {
  color: #39a288;
}
.wrapper.style2 strong, .wrapper.style2 b {
  color: #637485;
}
.wrapper.style2 h1, .wrapper.style2 h2, .wrapper.style2 h3, .wrapper.style2 h4, .wrapper.style2 h5, .wrapper.style2 h6 {
  color: #637485;
}
.wrapper.style2 blockquote {
  border-left-color: rgba(99, 116, 133, 0.25);
}
.wrapper.style2 code {
  background: rgba(99, 116, 133, 0.075);
  border-color: rgba(99, 116, 133, 0.25);
}
.wrapper.style2 hr {
  border-bottom-color: rgba(99, 116, 133, 0.25);
}
.wrapper.style2 .box {
  border-color: rgba(99, 116, 133, 0.25);
}
.wrapper.style2 input[type=submit],
.wrapper.style2 input[type=reset],
.wrapper.style2 input[type=button],
.wrapper.style2 button,
.wrapper.style2 .button {
  background-color: transparent;
  -webkit-box-shadow: inset 0 0 0 1px rgba(99, 116, 133, 0.25);
          box-shadow: inset 0 0 0 1px rgba(99, 116, 133, 0.25);
  color: #637485 !important;
}
.wrapper.style2 input[type=submit]:hover, .wrapper.style2 input[type=submit]:focus,
.wrapper.style2 input[type=reset]:hover,
.wrapper.style2 input[type=reset]:focus,
.wrapper.style2 input[type=button]:hover,
.wrapper.style2 input[type=button]:focus,
.wrapper.style2 button:hover,
.wrapper.style2 button:focus,
.wrapper.style2 .button:hover,
.wrapper.style2 .button:focus {
  background-color: rgba(99, 116, 133, 0.075);
}
.wrapper.style2 input[type=submit]:active,
.wrapper.style2 input[type=reset]:active,
.wrapper.style2 input[type=button]:active,
.wrapper.style2 button:active,
.wrapper.style2 .button:active {
  background-color: rgba(99, 116, 133, 0.175);
}
.wrapper.style2 input[type=submit].icon:before,
.wrapper.style2 input[type=reset].icon:before,
.wrapper.style2 input[type=button].icon:before,
.wrapper.style2 button.icon:before,
.wrapper.style2 .button.icon:before {
  color: #bbb;
}
.wrapper.style2 input[type=submit].primary,
.wrapper.style2 input[type=reset].primary,
.wrapper.style2 input[type=button].primary,
.wrapper.style2 button.primary,
.wrapper.style2 .button.primary {
  -webkit-box-shadow: none;
          box-shadow: none;
  background-color: #39a288;
  color: #ffffff !important;
}
.wrapper.style2 input[type=submit].primary.icon:before,
.wrapper.style2 input[type=reset].primary.icon:before,
.wrapper.style2 input[type=button].primary.icon:before,
.wrapper.style2 button.primary.icon:before,
.wrapper.style2 .button.primary.icon:before {
  color: #ffffff !important;
}
.wrapper.style2 input[type=submit].primary:hover, .wrapper.style2 input[type=submit].primary:focus,
.wrapper.style2 input[type=reset].primary:hover,
.wrapper.style2 input[type=reset].primary:focus,
.wrapper.style2 input[type=button].primary:hover,
.wrapper.style2 input[type=button].primary:focus,
.wrapper.style2 button.primary:hover,
.wrapper.style2 button.primary:focus,
.wrapper.style2 .button.primary:hover,
.wrapper.style2 .button.primary:focus {
  background-color: #40b598;
}
.wrapper.style2 input[type=submit].primary:active,
.wrapper.style2 input[type=reset].primary:active,
.wrapper.style2 input[type=button].primary:active,
.wrapper.style2 button.primary:active,
.wrapper.style2 .button.primary:active {
  background-color: #328f78;
}
.wrapper.style2 label {
  color: #637485;
}
.wrapper.style2 input[type=text],
.wrapper.style2 input[type=password],
.wrapper.style2 input[type=email],
.wrapper.style2 select,
.wrapper.style2 textarea {
  background-color: rgba(99, 116, 133, 0.075);
  border-color: transparent;
}
.wrapper.style2 input[type=text]:focus,
.wrapper.style2 input[type=password]:focus,
.wrapper.style2 input[type=email]:focus,
.wrapper.style2 select:focus,
.wrapper.style2 textarea:focus {
  border-color: #39a288;
  -webkit-box-shadow: 0 0 0 1px #39a288;
          box-shadow: 0 0 0 1px #39a288;
}
.wrapper.style2 select {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' preserveAspectRatio='none' viewBox='0 0 40 40'%3E%3Cpath d='M9.4,12.3l10.4,10.4l10.4-10.4c0.2-0.2,0.5-0.4,0.9-0.4c0.3,0,0.6,0.1,0.9,0.4l3.3,3.3c0.2,0.2,0.4,0.5,0.4,0.9 c0,0.4-0.1,0.6-0.4,0.9L20.7,31.9c-0.2,0.2-0.5,0.4-0.9,0.4c-0.3,0-0.6-0.1-0.9-0.4L4.3,17.3c-0.2-0.2-0.4-0.5-0.4-0.9 c0-0.4,0.1-0.6,0.4-0.9l3.3-3.3c0.2-0.2,0.5-0.4,0.9-0.4S9.1,12.1,9.4,12.3z' fill='rgba(99, 116, 133, 0.25)' /%3E%3C/svg%3E");
}
.wrapper.style2 select option {
  color: #637485;
  background: #f5f8fa;
}
.wrapper.style2 input[type=checkbox] + label,
.wrapper.style2 input[type=radio] + label {
  color: #637485;
}
.wrapper.style2 input[type=checkbox] + label:before,
.wrapper.style2 input[type=radio] + label:before {
  background: rgba(99, 116, 133, 0.075);
  border-color: rgba(99, 116, 133, 0.25);
}
.wrapper.style2 input[type=checkbox]:checked + label:before,
.wrapper.style2 input[type=radio]:checked + label:before {
  background-color: #39a288;
  border-color: #39a288;
  color: #ffffff;
}
.wrapper.style2 input[type=checkbox]:focus + label:before,
.wrapper.style2 input[type=radio]:focus + label:before {
  border-color: #39a288;
  -webkit-box-shadow: 0 0 0 1px #39a288;
          box-shadow: 0 0 0 1px #39a288;
}
.wrapper.style2 ::-webkit-input-placeholder {
  color: #bbb !important;
}
.wrapper.style2 :-moz-placeholder {
  color: #bbb !important;
}
.wrapper.style2 ::-moz-placeholder {
  color: #bbb !important;
}
.wrapper.style2 :-ms-input-placeholder {
  color: #bbb !important;
}
.wrapper.style2 .formerize-placeholder {
  color: #bbb !important;
}
.wrapper.style2 ul.alt li {
  border-top-color: rgba(99, 116, 133, 0.25);
}
.wrapper.style2 header p {
  color: #bbb;
}
.wrapper.style2 header.major p {
  color: #39a288;
}
.wrapper.style2 header.major:after {
  background-color: rgba(99, 116, 133, 0.25);
}
.wrapper.style2 table tbody tr {
  border-color: rgba(99, 116, 133, 0.25);
}
.wrapper.style2 table tbody tr:nth-child(2n + 1) {
  background-color: rgba(99, 116, 133, 0.075);
}
.wrapper.style2 table th {
  color: #637485;
}
.wrapper.style2 table thead {
  border-bottom-color: rgba(99, 116, 133, 0.25);
}
.wrapper.style2 table tfoot {
  border-top-color: rgba(99, 116, 133, 0.25);
}
.wrapper.style2 table.alt tbody tr td {
  border-color: rgba(99, 116, 133, 0.25);
}
.wrapper.style2 .spotlights article {
  background-color: #ffffff;
}
.wrapper.style2 .timeline > section {
  border-color: #bbb;
}
.wrapper.style2 .timeline > section:before {
  background-color: #bbb;
}
.wrapper.style2 .timeline header p {
  color: #39a288;
}
.wrapper.style2:before, .wrapper.style2:after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100' preserveAspectRatio='none'%3E%3Cpolygon points='0,0 0,100 100,100' style='fill:%23f5f8fa%3B' /%3E%3C/svg%3E");
}
@media screen and (max-width: 1280px) {
  .wrapper {
    padding: 5em 0 3em 0;
  }
  .wrapper:before, .wrapper:after {
    height: 4em;
  }
}
@media screen and (max-width: 980px) {
  .wrapper {
    padding: 4em 0 2em 0;
  }
  .wrapper:before, .wrapper:after {
    height: 3em;
  }
}
@media screen and (max-width: 736px) {
  .wrapper {
    padding: 3em 0 1em 0;
  }
  .wrapper > .inner {
    width: -webkit-calc(100% - 3em);
    width: calc(100% - 3em);
  }
  .wrapper:before, .wrapper:after {
    height: 2em;
  }
  .wrapper.alt {
    padding-bottom: 0;
  }
}

/* Header */
#header {
  -moz-align-items: center;
  -webkit-align-items: center;
  -ms-align-items: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -moz-justify-content: -moz-flex-end;
  -webkit-justify-content: -webkit-flex-end;
  -ms-justify-content: -ms-flex-end;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
     transition-duration: 0.2s;
  -webkit-transition-property: background-color padding;
  -o-transition-property: background-color padding;
  transition-property: background-color padding;
  -webkit-transition-timing-function: ease-in-out;
  -o-transition-timing-function: ease-in-out;
     transition-timing-function: ease-in-out;
  background: #3b4147;
  color: #ffffff;
  left: 0;
  padding: 1em;
  position: fixed;
  text-transform: uppercase;
  top: 0;
  width: 100%;
  z-index: 10001;
}
#header > .logo {
  -moz-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  -webkit-box-flex: 1;
          flex: 1;
  display: block;
  cursor: default;
  white-space: nowrap;
}
#header > .logo a {
  -webkit-transition: color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out;
  color: inherit;
  text-decoration: none;
  font-weight: 700;
}
#header > a {
  -webkit-transition: color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out;
  padding: 0 0.75em;
  color: inherit;
  font-weight: 700;
  text-decoration: none;
  display: block;
}
#header > a:hover, #header > a:focus {
  color: #ffffff;
}
#header > a[href="#menu"] {
  -webkit-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  -o-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-border-radius: 4px;
          border-radius: 4px;
  -webkit-box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.25);
          box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.25);
  font-weight: 700;
  margin-left: 0.5em;
  padding: 0.5em 1.25em;
}
#header > a[href="#menu"]:before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  text-transform: none !important;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}
#header > a[href="#menu"]:before {
  content: "";
  float: right;
  line-height: inherit;
}
#header > a[href="#menu"]:hover {
  background-color: rgba(255, 255, 255, 0.065);
}
#header.alt {
  background-color: transparent;
  padding: 1.5em 2em;
}
@media screen and (max-width: 736px) {
  #header {
    -moz-align-items: -moz-flex-start;
    -webkit-align-items: -webkit-flex-start;
    -ms-align-items: -ms-flex-start;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    padding: 0 0 0 1em;
  }
  #header > .logo {
    padding: 0.5em 0;
  }
  #header > .logo span {
    display: none;
  }
  #header > a[href="#menu"] {
    -webkit-box-shadow: none;
            box-shadow: none;
    position: relative;
    text-indent: 8em;
    white-space: nowrap;
    width: 3.5em;
  }
  #header > a[href="#menu"]:before {
    left: 0;
    margin-left: 0;
    padding: 0.5em 0;
    position: absolute;
    text-align: center;
    text-indent: 0;
    top: 0;
    width: inherit;
  }
  #header > a[href="#menu"]:hover {
    background-color: transparent;
  }
  #header.alt {
    padding: 0.5em 0 0.5em 1em;
  }
}
@media screen and (max-width: 480px) {
  #header {
    min-width: 320px;
  }
}

/* Menu */
#page-wrapper {
  -webkit-transition: -webkit-filter 0.25s ease;
  transition: -webkit-filter 0.25s ease;
  -o-transition: filter 0.25s ease;
  transition: filter 0.25s ease;
  transition: filter 0.25s ease, -webkit-filter 0.25s ease;
}

#menu {
  -moz-align-items: center;
  -webkit-align-items: center;
  -ms-align-items: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -moz-justify-content: center;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  pointer-events: none;
  -webkit-transition: opacity 0.5s ease, visibility 0.5s;
  -o-transition: opacity 0.5s ease, visibility 0.5s;
  transition: opacity 0.5s ease, visibility 0.5s;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background-color: rgba(35, 67, 107, 0.9);
  cursor: default;
  height: 100%;
  left: 0;
  opacity: 0;
  position: fixed;
  text-align: center;
  top: 0;
  visibility: hidden;
  width: 100%;
  padding: 1.5em;
  z-index: 10002;
}
#menu .inner {
  padding: 2.5em 1.5em 0.5em 1.5em;
  -webkit-transform: translateY(0.5em);
  -ms-transform: translateY(0.5em);
  transform: translateY(0.5em);
  -webkit-transition: opacity 0.5s ease, -webkit-transform 0.5s ease;
  transition: opacity 0.5s ease, -webkit-transform 0.5s ease;
  -o-transition: opacity 0.5s ease, transform 0.5s ease;
  transition: opacity 0.5s ease, transform 0.5s ease;
  transition: opacity 0.5s ease, transform 0.5s ease, -webkit-transform 0.5s ease;
  -webkit-overflow-scrolling: touch;
  background: #39a288;
  -webkit-border-radius: 4px;
          border-radius: 4px;
  color: #ffffff;
  display: block;
  max-width: 100%;
  opacity: 0;
  position: relative;
  text-transform: uppercase;
  width: 24em;
}
#menu h2 {
  color: inherit;
  border-bottom: solid 2px rgba(255, 255, 255, 0.25);
  padding-bottom: 1em;
}
#menu .close {
  text-decoration: none;
  content: "";
  cursor: pointer;
  display: block;
  height: 4em;
  overflow: hidden;
  position: absolute;
  right: 0;
  text-align: center;
  text-indent: 4em;
  top: 0;
  width: 4em;
  color: #ffffff;
}
#menu .close:before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  text-transform: none !important;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}
#menu .close:before {
  content: "";
  position: absolute;
  top: 0.75em;
  right: 0.75em;
  font-size: 1.25em;
  width: 1em;
  height: 1em;
  line-height: 1em;
  display: block;
  text-indent: 0;
}
#menu .links {
  list-style: none;
  margin-bottom: 1.5em;
  padding: 0;
}
#menu .links li {
  padding: 0;
}
#menu .links li a {
  background-color: transparent;
  -webkit-border-radius: 4px;
          border-radius: 4px;
  border: 0;
  color: inherit;
  display: block;
  font-weight: 400;
  line-height: 1.85em;
  padding: 0.75em 0;
  text-decoration: none;
}
#menu .links li a:hover {
  color: #39a288;
  background-color: #ffffff;
}
#menu .links li .top-line {
  color: inherit;
  -webkit-border-radius: 0;
          border-radius: 0;
  border-top: solid 2px rgba(255, 255, 255, 0.25);
}
@media screen and (max-width: 736px) {
  #menu .inner {
    max-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }
  #menu .inner .close {
    -webkit-background-size: 1.5em 1.5em;
            background-size: 1.5em 1.5em;
  }
}

body.is-menu-visible #page-wrapper {
  -moz-filter: blur(1.5px);
  -webkit-filter: blur(1.5px);
  -ms-filter: blur(1.5px);
  filter: blur(1.5px);
}
body.is-menu-visible #menu {
  pointer-events: auto;
  opacity: 1;
  visibility: visible;
}
body.is-menu-visible #menu .inner {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
}

/* Banner */
#banner {
  background-image: -webkit-linear-gradient(to top, rgba(43, 90, 150, 0.9), rgba(43, 90, 150, 0.9)), url("../../images/banner.jpg");
  background-image: -webkit-gradient(linear, left bottom, left top, from(rgba(43, 90, 150, 0.9)), to(rgba(43, 90, 150, 0.9))), url("../../images/banner.jpg");
  background-image: -webkit-linear-gradient(bottom, rgba(43, 90, 150, 0.9), rgba(43, 90, 150, 0.9)), url("../../images/banner.jpg");
  background-image: -o-linear-gradient(bottom, rgba(43, 90, 150, 0.9), rgba(43, 90, 150, 0.9)), url("../../images/banner.jpg");
  background-image: linear-gradient(to top, rgba(43, 90, 150, 0.9), rgba(43, 90, 150, 0.9)), url("../../images/banner.jpg");
  background-attachment: fixed;
  background-position: center center;
  -webkit-background-size: cover;
          background-size: cover;
  color: #fff;
  padding: 16em 3em;
  position: relative;
  text-align: center;
}
#banner h1 {
  color: inherit;
  font-size: 3.2em;
  margin: 0 0 0.65em 0;
  padding: 0;
  text-transform: uppercase;
}
#banner p {
  font-size: 1.75em;
  margin-bottom: 0.75em;
}
#banner .button {
  min-width: 14em;
}
#banner > .inner {
  -webkit-transition: opacity 1s ease-in-out, -webkit-transform 1s ease-in-out;
  transition: opacity 1s ease-in-out, -webkit-transform 1s ease-in-out;
  -o-transition: opacity 1s ease-in-out, transform 1s ease-in-out;
  transition: opacity 1s ease-in-out, transform 1s ease-in-out;
  transition: opacity 1s ease-in-out, transform 1s ease-in-out, -webkit-transform 1s ease-in-out;
  opacity: 1;
  position: relative;
  z-index: 1;
}
#banner:after {
  -webkit-transition: opacity 1s ease-in-out;
  -o-transition: opacity 1s ease-in-out;
  transition: opacity 1s ease-in-out;
  pointer-events: none;
  content: "";
  display: block;
  background-color: #23436b;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
body.is-preload #banner .inner {
  -webkit-transform: translateY(1em);
  -ms-transform: translateY(1em);
  transform: translateY(1em);
  opacity: 0;
}
body.is-preload #banner:after {
  opacity: 1;
}
@media screen and (max-width: 1280px) {
  #banner {
    background-attachment: scroll;
    padding: 16em 3em;
  }
}
@media screen and (max-width: 980px) {
  #banner h1 {
    font-size: 2.8em;
  }
}
@media screen and (max-width: 736px) {
  #banner {
    padding: 8em 2em;
  }
  #banner h1 {
    font-size: 2.25em;
  }
  #banner p {
    font-size: 1.25em;
  }
}
@media screen and (max-width: 360px) {
  #banner {
    padding: 6em 2em;
  }
}

/* Main */
#main {
  padding: 10em 0 4em;
}
@media screen and (max-width: 736px) {
  #main {
    padding: 5em 0 2em;
  }
}

/* Footer */
#footer {
  background-image: -webkit-linear-gradient(to top, rgba(43, 90, 150, 0.9), rgba(43, 90, 150, 0.9)), url("../../images/banner.jpg");
  background-image: -webkit-gradient(linear, left bottom, left top, from(rgba(43, 90, 150, 0.9)), to(rgba(43, 90, 150, 0.9))), url("../../images/banner.jpg");
  background-image: -webkit-linear-gradient(bottom, rgba(43, 90, 150, 0.9), rgba(43, 90, 150, 0.9)), url("../../images/banner.jpg");
  background-image: -o-linear-gradient(bottom, rgba(43, 90, 150, 0.9), rgba(43, 90, 150, 0.9)), url("../../images/banner.jpg");
  background-image: linear-gradient(to top, rgba(43, 90, 150, 0.9), rgba(43, 90, 150, 0.9)), url("../../images/banner.jpg");
  background-attachment: fixed;
  background-position: center center;
  -webkit-background-size: cover;
          background-size: cover;
  padding: 12em 0 2em;
}
#footer > .inner {
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 auto 3em;
  max-width: 75em;
  width: -webkit-calc(100% - 4em);
  width: calc(100% - 4em);
}
#footer > .inner.fill-screen {
  height: 40vh;
}
#footer .contact {
  padding: 4em 2.5em 2em 2.5em;
  background-color: #fff;
  list-style-type: none;
  margin-bottom: 0;
  width: 35%;
}
#footer .contact li {
  margin-bottom: 2em;
  padding-bottom: 2em;
  padding-left: 4em;
  position: relative;
}
#footer .contact li strong {
  color: #39a288;
  display: block;
  margin-bottom: 0.5em;
  text-transform: uppercase;
}
#footer .contact li.icon:before {
  color: rgba(99, 116, 133, 0.25);
  position: absolute;
  left: 0;
  top: 0.25em;
  font-size: 1.25em;
}
#footer .contact li:after {
  background-color: rgba(99, 116, 133, 0.25);
  bottom: 0;
  content: "";
  height: 1px;
  left: -4em;
  position: absolute;
  width: 8em;
}
#footer .contact li:last-child {
  margin-bottom: 0;
}
#footer .contact li:last-child:after {
  display: none;
}
#footer form {
  background-color: #39a288;
  color: #ffffff;
  -moz-flex-grow: 1;
  -webkit-flex-grow: 1;
  -ms-flex-grow: 1;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  margin-bottom: 0;
  padding: 4em 3.5em;
}
#footer form input, #footer form select, #footer form textarea {
  color: #ffffff;
}
#footer form a {
  color: #ffffff;
}
#footer form strong, #footer form b {
  color: #ffffff;
}
#footer form h1, #footer form h2, #footer form h3, #footer form h4, #footer form h5, #footer form h6 {
  color: #ffffff;
}
#footer form blockquote {
  border-left-color: rgba(255, 255, 255, 0.25);
}
#footer form code {
  background: rgba(255, 255, 255, 0.065);
  border-color: rgba(255, 255, 255, 0.25);
}
#footer form hr {
  border-bottom-color: rgba(255, 255, 255, 0.25);
}
#footer form .box {
  border-color: rgba(255, 255, 255, 0.25);
}
#footer form input[type=submit],
#footer form input[type=reset],
#footer form input[type=button],
#footer form button,
#footer form .button {
  background-color: transparent;
  -webkit-box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.25);
          box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.25);
  color: #ffffff !important;
}
#footer form input[type=submit]:hover, #footer form input[type=submit]:focus,
#footer form input[type=reset]:hover,
#footer form input[type=reset]:focus,
#footer form input[type=button]:hover,
#footer form input[type=button]:focus,
#footer form button:hover,
#footer form button:focus,
#footer form .button:hover,
#footer form .button:focus {
  background-color: rgba(255, 255, 255, 0.065);
}
#footer form input[type=submit]:active,
#footer form input[type=reset]:active,
#footer form input[type=button]:active,
#footer form button:active,
#footer form .button:active {
  background-color: rgba(255, 255, 255, 0.165);
}
#footer form input[type=submit].icon:before,
#footer form input[type=reset].icon:before,
#footer form input[type=button].icon:before,
#footer form button.icon:before,
#footer form .button.icon:before {
  color: rgba(255, 255, 255, 0.75);
}
#footer form input[type=submit].primary,
#footer form input[type=reset].primary,
#footer form input[type=button].primary,
#footer form button.primary,
#footer form .button.primary {
  -webkit-box-shadow: none;
          box-shadow: none;
  background-color: #ffffff;
  color: #39a288 !important;
}
#footer form input[type=submit].primary.icon:before,
#footer form input[type=reset].primary.icon:before,
#footer form input[type=button].primary.icon:before,
#footer form button.primary.icon:before,
#footer form .button.primary.icon:before {
  color: #39a288 !important;
}
#footer form label {
  color: #ffffff;
}
#footer form input[type=text],
#footer form input[type=password],
#footer form input[type=email],
#footer form select,
#footer form textarea {
  background-color: rgba(0, 0, 0, 0.065);
  border-color: transparent;
}
#footer form input[type=text]:focus,
#footer form input[type=password]:focus,
#footer form input[type=email]:focus,
#footer form select:focus,
#footer form textarea:focus {
  border-color: #ffffff;
  -webkit-box-shadow: 0 0 0 1px #ffffff;
          box-shadow: 0 0 0 1px #ffffff;
}
#footer form select {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' preserveAspectRatio='none' viewBox='0 0 40 40'%3E%3Cpath d='M9.4,12.3l10.4,10.4l10.4-10.4c0.2-0.2,0.5-0.4,0.9-0.4c0.3,0,0.6,0.1,0.9,0.4l3.3,3.3c0.2,0.2,0.4,0.5,0.4,0.9 c0,0.4-0.1,0.6-0.4,0.9L20.7,31.9c-0.2,0.2-0.5,0.4-0.9,0.4c-0.3,0-0.6-0.1-0.9-0.4L4.3,17.3c-0.2-0.2-0.4-0.5-0.4-0.9 c0-0.4,0.1-0.6,0.4-0.9l3.3-3.3c0.2-0.2,0.5-0.4,0.9-0.4S9.1,12.1,9.4,12.3z' fill='rgba(255, 255, 255, 0.25)' /%3E%3C/svg%3E");
}
#footer form select option {
  color: #ffffff;
  background: #39a288;
}
#footer form input[type=checkbox] + label,
#footer form input[type=radio] + label {
  color: #ffffff;
}
#footer form input[type=checkbox] + label:before,
#footer form input[type=radio] + label:before {
  background: rgba(0, 0, 0, 0.065);
  border-color: rgba(255, 255, 255, 0.25);
}
#footer form input[type=checkbox]:checked + label:before,
#footer form input[type=radio]:checked + label:before {
  background-color: #ffffff;
  border-color: #ffffff;
  color: #39a288;
}
#footer form input[type=checkbox]:focus + label:before,
#footer form input[type=radio]:focus + label:before {
  border-color: #ffffff;
  -webkit-box-shadow: 0 0 0 1px #ffffff;
          box-shadow: 0 0 0 1px #ffffff;
}
#footer form ::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.75) !important;
}
#footer form :-moz-placeholder {
  color: rgba(255, 255, 255, 0.75) !important;
}
#footer form ::-moz-placeholder {
  color: rgba(255, 255, 255, 0.75) !important;
}
#footer form :-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.75) !important;
}
#footer form .formerize-placeholder {
  color: rgba(255, 255, 255, 0.75) !important;
}
#footer form ul.alt li {
  border-top-color: rgba(255, 255, 255, 0.25);
}
#footer form header p {
  color: rgba(255, 255, 255, 0.75);
}
#footer form header.major p {
  color: #ffffff;
}
#footer form header.major:after {
  background-color: rgba(255, 255, 255, 0.25);
}
#footer form table tbody tr {
  border-color: rgba(255, 255, 255, 0.25);
}
#footer form table tbody tr:nth-child(2n + 1) {
  background-color: rgba(255, 255, 255, 0.065);
}
#footer form table th {
  color: #ffffff;
}
#footer form table thead {
  border-bottom-color: rgba(255, 255, 255, 0.25);
}
#footer form table tfoot {
  border-top-color: rgba(255, 255, 255, 0.25);
}
#footer form table.alt tbody tr td {
  border-color: rgba(255, 255, 255, 0.25);
}
#footer form .spotlights article {
  background-color: rgba(255, 255, 255, 0.065);
}
#footer form .timeline > section {
  border-color: rgba(255, 255, 255, 0.75);
}
#footer form .timeline > section:before {
  background-color: rgba(255, 255, 255, 0.75);
}
#footer form .timeline header p {
  color: #ffffff;
}
#footer form h4 {
  font-weight: 700;
  margin-bottom: 2em;
  text-transform: uppercase;
}
#footer form label {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
@media screen and (max-width: 980px) {
  #footer form > .fields {
    width: -webkit-calc(100% + 3em);
    width: calc(100% + 3em);
    margin: -1.5em 0 2em -1.5em;
  }
  #footer form > .fields > .field {
    padding: 1.5em 0 0 1.5em;
    width: -webkit-calc(100% - 1.5em);
    width: calc(100% - 1.5em);
  }
  #footer form > .fields > .field.half {
    width: -webkit-calc(100% - 1.5em);
    width: calc(100% - 1.5em);
  }
  #footer form > .fields > .field.third {
    width: -webkit-calc(100% - 1.5em);
    width: calc(100% - 1.5em);
  }
  #footer form > .fields > .field.quarter {
    width: -webkit-calc(100% - 1.5em);
    width: calc(100% - 1.5em);
  }
}
#footer .copyright {
  color: #bbb;
  margin-bottom: 3em;
  padding: 0;
  text-align: center;
}
#footer .copyright li {
  border-left: solid 1px rgba(99, 116, 133, 0.25);
  display: inline-block;
  list-style: none;
  margin-left: 1.5em;
  padding-left: 1.5em;
}
#footer .copyright li:first-child {
  border-left: 0;
  margin-left: 0;
  padding-left: 0;
}
@media screen and (max-width: 1280px) {
  #footer {
    background-attachment: scroll;
  }
}
@media screen and (max-width: 980px) {
  #footer {
    padding-top: 10em;
  }
  #footer .contact {
    padding: 2em 2em 0.1em 2em;
    width: 45%;
  }
  #footer .contact li {
    padding-left: 3em;
  }
  #footer form {
    padding: 2em 2em;
    width: 55%;
  }
}
@media screen and (max-width: 736px) {
  #footer {
    padding-top: 7em;
  }
  #footer > .inner {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: -webkit-calc(100% - 3em);
    width: calc(100% - 3em);
  }
  #footer .contact {
    width: 100%;
  }
  #footer form {
    width: 100%;
  }
  #footer .copyright {
    margin-bottom: 2em;
  }
  #footer .copyright li {
    display: block;
    border-left: 0;
    margin-left: 0;
    padding-left: 0;
  }
}
@media screen and (max-width: 480px) {
  #footer .contact {
    padding: 2em 1.5em 0.1em 1.5em;
  }
  #footer .contact li {
    padding-left: 2.5em;
  }
  #footer form {
    padding: 2em 1.5em;
  }
}